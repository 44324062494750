<template>
  <EggPreloader v-if="showEggPreloader" :show-go-to-home-btn="false" />
  <div class="page-events" v-else>
    <div class="logo">
      <img src="@/assets/img/svg/logo.svg" alt="" />
    </div>

    <div class="page-events__title">Main event heading</div>

    <div class="page-events__notice">
      {{ $t("page-event-notice") }}
    </div>

    <div class="page-events__chars">
      <ShoppiesList
        :filterable="true"
        :hide-rarity="true"
        :items="eventItems"
        :clickable="true"
        :show-load-more-btn="false"
        @applyAsFilter="makeFilterByRarity"
      />
    </div>

    <div class="event-price">
      <div class="event-price__img">
        <CoinGoldSvg />
      </div>

      <div class="event-price__title">
        {{ $t("event-price-title") }}
      </div>

      <div class="event-price__amount" v-if="prizePool">
        SUB {{ formatNumber(prizePool, true) }}
      </div>
    </div>

    <section class="mates-community home-mates">
      <div class="page-mates__head">
        <div class="page-mates__title">
          {{ $t("mates-my-col") }}
        </div>

        <Filters @filter="makeFilter" />
      </div>

      <ShoppiesList
        :lang="lang"
        v-if="items.length"
        :items="items"
        :show-load-more-btn="showLoadMoreBtn"
        @loadMore="getUserCryptoshoppies"
      />
    </section>

    <transition name="fade">
      <TopUpPopup
        v-if="showTopUpModal"
        @close="
          showTopUpModal = false;
          setBalanceToppedUp(0);
        "
        :balance="+getBalanceToppedUp"
      />
    </transition>
  </div>
</template>

<script>
import ShoppiesList from "../../components/common/ShoppiesList";
import CoinGoldSvg from "../../components/icons/CoinGoldSvg";
import api from "@/api/api";
import formatNumberMixin from "@/mixins/common/FormatNumberMixin";
import Filters from "../../components/common/Filters";
import TopUpPopup from "@/components/modals/TopUpPopup";
import { mapActions, mapGetters, mapMutations } from "vuex";
import errors from "@/utils/errors";
import ValidateCurrencyCardMixin from "../../mixins/cards/ValidateCurrencyCardMixin";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";
import successes from "../../utils/successes";
import EggPreloader from "../../components/utils/EggPreloader";

export default {
  name: "Home",
  mixins: [ValidateCurrencyCardMixin, SetProcessingMixin, formatNumberMixin],
  data: () => ({
    items: [],
    phenotypeShortKey: "",
    eventItems: [],
    skip: 0,
    limit: 9,
    loading: false,
    showLoadMoreBtn: false,
    showTopUpModal: false,
    lang: "en",
    isContestModalVisible: false,
    prizePool: 0,
    cshOwn: [],
    filter: null,
    showEggPreloader: false,
  }),
  computed: {
    ...mapGetters({
      getBalanceToppedUp: "wallet/getBalanceToppedUp",
    }),
    isFirstScan() {
      return this.items.length <= 1 && !this.loading;
    },
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    ...mapMutations({
      setToken: "user/setToken",
      setBalanceToppedUp: "wallet/setBalanceToppedUp",
    }),
    makeFilterByRarity(phenotypeShortKey) {
      if (phenotypeShortKey === this.phenotypeShortKey) {
        this.phenotypeShortKey = "";
      } else {
        this.phenotypeShortKey = phenotypeShortKey;
      }
      this.makeFilter(this.filter);
    },
    makeFilter(filter) {
      this.filter = filter;
      this.items = [];
      this.skip = 0;
      this.getUserCryptoshoppies();
    },
    async getUserCryptoshoppies() {
      try {
        const response = await api.getUserCryptoshoppies({
          limit: this.limit,
          skip: this.skip,
          filters: this.filter || null,
          sortByMask: this.phenotypeShortKey,
        });
        this.items = [...this.items, ...response.cryptoshoppies];
        this.showLoadMoreBtn = this.items.length < response.total;
        this.skip += this.limit;
      } catch (e) {
        console.log(e);
      }
    },
    async getMainEvent() {
      try {
        const response = await api.getMainEvent();
        this.eventItems = response.result;
        this.prizePool = +response.prizePool;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      this.setLoading(true);
      await this.getMainEvent();
      if (this.getBalanceToppedUp) {
        this.showTopUpModal = true;
      }
      if (this.$route.query.code) {
        let code = encodeURIComponent(
          this.$route.query.code.toString()
        ).replace(/%20/g, "+");
        const response = await this.validateCurrencyCard(code);
        if (response.isUsed) {
          this.setLoading(false);
          this.setError(errors.CARD_ALREADY_USED);
          await this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              code: undefined,
            },
          });
          return;
        }
        const query = {
          code,
          balance: response.balance,
        };
        this.lang = localStorage.getItem("lang");
        if (!response.containsCharacter) {
          await api.useCurrencyCardAsCurrency(query.code);
          await this.loadBalance();
          this.setBalanceToppedUp(query.balance);
          this.showTopUpModal = true;
        } else {
          this.setLoading(false);
          this.showEggPreloader = true;
          await api.useCurrencyCardAsCharacter(query.code);
          this.setSuccess({ message: successes.NEW_CHARACTER_HAS_BEEN_MINTED });
          this.showEggPreloader = false;
        }
      }
    } catch (e) {
      this.setError({
        message: e.response.data.error.message,
      });
    } finally {
      await this.getUserCryptoshoppies();
      this.limit = 9;
      this.setLoading(false);
      this.showEggPreloader = false;
      await this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          code: undefined,
        },
      });
    }
  },
  components: {
    EggPreloader,
    Filters,
    CoinGoldSvg,
    ShoppiesList,
    TopUpPopup,
  },
};
</script>
