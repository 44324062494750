<template>
  <div class="home-page char-bg">
    <SwiperItem v-if="items.length" :item="items[0]" />
    <Rules
      v-if="isFirstScan"
      @click="$router.push({ name: 'Rules' })"
      :title="$t('rules-box-title')"
      :text="$t('rules-box-text')"
    />

    <ShoppiesList
      :lang="lang"
      v-if="userCollectionShoppies.length"
      :items="userCollectionShoppies"
      :show-load-more-btn="showLoadMoreBtn"
      @loadMore="getUserCryptoshoppies"
    />
    <transition name="fade">
      <TopUpPopup
        v-if="showTopUpModal"
        @close="showTopUpModal = false"
        :balance="+getBalanceToppedUp"
      />
    </transition>
  </div>
</template>

<script>
import api from "@/api/api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ShoppiesList from "../../components/common/ShoppiesList";
import SwiperItem from "@/components/swiper/SwiperItem";
import ValidateCurrencyCardMixin from "@/mixins/cards/ValidateCurrencyCardMixin";
import TopUpPopup from "@/components/modals/TopUpPopup";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";
import errors from "@/utils/errors";
import Rules from "../../components/common/Rules";

export default {
  name: "Shoppie",
  mixins: [ValidateCurrencyCardMixin, SetProcessingMixin],
  data: () => ({
    items: [],
    skip: 0,
    limit: 9,
    loading: false,
    showLoadMoreBtn: false,
    showTopUpModal: false,
    lang: "en",
  }),
  computed: {
    ...mapGetters({
      getBalanceToppedUp: "wallet/getBalanceToppedUp",
    }),
    isFirstScan() {
      return this.items.length <= 1 && !this.loading;
    },
    userCollectionShoppies() {
      if (this.items.length <= 1) {
        return [];
      }
      let copy = [...this.items];
      copy.shift();
      return copy;
    },
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    ...mapMutations({
      setToken: "user/setToken",
      setBalanceToppedUp: "wallet/setBalanceToppedUp",
    }),
    async getUserCryptoshoppies() {
      try {
        const response = await api.getUserCryptoshoppies({
          limit: this.limit,
          skip: this.skip,
        });
        this.items = [...this.items, ...response.cryptoshoppies];
        this.showLoadMoreBtn = this.items.length < response.total;
        this.skip += this.limit;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      this.setLoading(true);
      if (this.getBalanceToppedUp) {
        this.showTopUpModal = true;
      }
      if (this.$route.query.code) {
        const code = encodeURIComponent(this.$route.query.code.toString());
        const response = await this.validateCurrencyCard(code);
        if (response.isUsed) {
          this.setError(errors.CARD_ALREADY_USED);
          await this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              code: undefined,
            },
          });
          return;
        }
        const query = {
          code,
          balance: response.balance,
        };
        this.lang = localStorage.getItem("lang");
        if (!response.containsCharacter) {
          await api.useCurrencyCardAsCurrency(query.code);
          await this.loadBalance();
          this.setBalanceToppedUp(query.balance);
          return await this.$router.push({
            path: `/${this.lang ? this.lang : "en"}/market`,
          });
        } else {
          await api.useCurrencyCardAsCharacter(query.code);
        }
      }
    } catch (e) {
      this.setError({
        message: e.response.data.error.message,
      });
    } finally {
      await this.getUserCryptoshoppies();
      this.limit = 9;
      this.setLoading(false);
      await this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          code: undefined,
        },
      });
    }
  },
  components: {
    Rules,
    TopUpPopup,
    SwiperItem,
    ShoppiesList,
  },
};
</script>
