<template>
  <CryptoShoppieInfo
    :character-image="characterImage"
    :nft-id="nftId"
    :character-name="characterName"
    :creator-name="creatorName"
    :character-id="characterId"
    :to="`cryptoshoppie/${characterId}`"
  />
</template>
<script>
import CryptoShoppieInfo from "@/components/shoppie/CryptoShoppieInfo";
import LoadShoppieByIdMixin from "@/mixins/loadShoppieByIdMixin";
import api from "@/api/api";
export default {
  mixins: [LoadShoppieByIdMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    creator: null,
  }),
  computed: {
    creatorName() {
      return this.creator ? this.creator.name : "";
    },
  },
  methods: {
    async getUserById() {
      try {
        this.creator = await api.getUserById(this.hatchedBy);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.character = this.item;
    this.getUserById();
  },
  components: { CryptoShoppieInfo },
};
</script>
