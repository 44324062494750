<template>
  <div class="container">
    <div class="choice-page">
      <div class="logo">
        <img src="@/assets/img/svg/logo.svg" alt="" />
      </div>

      <!--      <div class="choice-page__text">-->
      <!--        {{ $t("choice-title") }}-->
      <!--      </div>-->

      <div class="page-image">
        <!--        <EggMystic v-if="creatureRace"/>-->
        <EggMystic />
        <!--        <EggAnimal v-if="animalRace"/>
        <img src="@/assets/img/egg-aliens.png" alt="" v-if="alienRace" />-->
      </div>

      <button class="game-btn" @click="goToHome" v-if="showGoToHomeBtn">
        {{ $t("popup-btn") }}
      </button>
    </div>
  </div>
</template>
<script>
import EggMystic from "../eggs/EggMystic";
export default {
  name: "EggPreloader",
  props: {
    showGoToHomeBtn: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  methods: {
    async goToHome() {
      await this.$router.push({
        path: "home",
      });
    },
  },
  components: { EggMystic },
};
</script>
