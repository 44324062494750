<template>
  <div class="egg egg--mystic">
    <div class="egg__img wiggle">
      <img fetchpriority="high" src="@/assets/img/egg-mystic.png" alt="egg" />
      <!--    <link rel="preload" as="image" href="src/assets/img/egg-mystic.png">-->
      <div class="egg__shine"></div>
    </div>

    <div class="egg__cover">
      <img
        fetchpriority="high"
        src="@/assets/img/egg-first-plan.png"
        alt="egg-bg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EggMystic",
};
</script>

<style scoped></style>
