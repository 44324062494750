<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-top">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>

        <div class="popup__content">
          <div class="popup__img">
            <img src="@/assets/img/coin-img.png" alt="" />
          </div>

          <div class="popup__title">
            {{ $t("top-up-title") }}
          </div>

          <div class="popup__text">
            {{ $t("top-up-text") }}
            <span class="amount"
              >SUB {{ balanceBeforeDot(balance) }}.<span class="amount-sm">{{
                balanceAfterDot(balance)
              }}</span></span
            >
          </div>

          <button class="popup__btn" @click="close">
            {{ $t("popup-btn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormatPriceMixin from "@/mixins/common/FormatPriceMixin";
import { mapMutations } from "vuex";
import CloseModalMixin from "../../mixins/common/CloseModalMixin";

export default {
  name: "TopUpPopup",
  mixins: [FormatPriceMixin, CloseModalMixin],
  props: {
    balance: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapMutations({
      setBalanceToppedUp: "wallet/setBalanceToppedUp",
    }),
    close() {
      this.setBalanceToppedUp(null);
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
